import React, { useState } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import { Box, IconButton, Popover, Typography } from "@mui/material"
import Sensor from "../../models/sensor"
import InfoIcon from "@mui/icons-material/Info"
import SensorDetailsView from "./SensorDetailsView"
import { convertedTemperatureValue } from "../../utilities/calculations"
import SampleUnit from "../../models/units"
import { samplesAfterPlacementDate } from "../../utilities/samples"
import Placement from "../../models/placement"
import { maturityForSensor } from "../../utilities/maturity"
import moment from "moment"

interface Props {
  sensor: Sensor
  temperatureUnit: SampleUnit
  color: string
  selected: boolean
  timezone: string
  onSelectionChange: () => void
  placement: Placement
}

const SensorView = (props: Props) => {

  const [sensorDetailsAnchorElement, setSensorDetailsAnchorElement] = useState<HTMLElement | null>(null)

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setSensorDetailsAnchorElement(event.currentTarget)
  }

  const popoverIsOpen = Boolean(sensorDetailsAnchorElement)
  const popoverId = popoverIsOpen ? "sensor-details" : undefined

  const samples = samplesAfterPlacementDate(props.sensor)
  const lastSample = samples.length > 0 ? samples[samples.length - 1] : null

  const getSensorDisplayValue = () => {
    const lastSample = samples.length > 0 ? samples[samples.length - 1] : null
    if (lastSample === null) {
      return null
    }
    if (props.placement.maturityMethod.type === "disabled") {
      return `${convertedTemperatureValue(lastSample.temperature, props.temperatureUnit).toFixed(1)}º${props.temperatureUnit.toUpperCase()}`
    } else if (props.placement.maturityMethod.curveFit.type === "disabled") {
      const maturity = maturityForSensor(props.sensor, props.placement)
      const lastMaturity = maturity[maturity.length - 1]
      if (props.placement.maturityMethod.type === "arrhenius") {
        return `${lastMaturity.maturityValue.toLocaleString(undefined, {maximumFractionDigits: 0})} hr`
      } else {
        return `${lastMaturity.maturityValue.toLocaleString(undefined, {maximumFractionDigits: 0})} ${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
      }
    } else {
      const maturity = maturityForSensor(props.sensor, props.placement)
      const lastMaturity = maturity[maturity.length - 1]
      if (lastMaturity.strengthValue === undefined) {
        return null
      }
      if (props.placement.maturityMethod.curveFit.parameters?.strengthUnit === "psi") {
        return `${lastMaturity.strengthValue.toLocaleString(undefined, {maximumFractionDigits: 0})} psi`
      } else {
        return `${lastMaturity.strengthValue.toLocaleString(undefined, {maximumFractionDigits: 2})} MPa`
      }
    }

  }


  return (
    <>
      <Box 
        display="flex" 
        flexDirection="row" 
        alignItems="center" 
        justifyContent="space-between" 
        sx={{
          width: "100%",
          cursor: "pointer"
        }}
        onClick={props.onSelectionChange}
      >
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
          {props.selected &&
            <CheckCircleIcon htmlColor={props.color} />
          }
          {!props.selected &&
            <CircleOutlinedIcon htmlColor={props.color} />
          }
          <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{
            marginLeft: "12px"
          }}>
            <Typography variant="body1" fontWeight="bold">
              {props.sensor.name ? props.sensor.name : props.sensor.serial}
            </Typography>
            {props.sensor.samples !== undefined && props.sensor.samples.length > 0 &&
              <Typography>
                {moment(props.sensor.samples[props.sensor.samples.length - 1].time).format("YYYY/MM/DD, HH:mm A")}
              </Typography>
            }
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {lastSample &&
            <Typography variant="subtitle1">
              {getSensorDisplayValue() ?? ""}
            </Typography>
          }
          <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault()
            event.stopPropagation()
            togglePopover(event)
          }}>
            <InfoIcon />
          </IconButton>
        </Box>
      </Box>
      <Popover 
        id={popoverId}
        open={popoverIsOpen}
        anchorEl={sensorDetailsAnchorElement}
        onClose={() => { setSensorDetailsAnchorElement(null) }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        <SensorDetailsView sensor={props.sensor} timezone={props.timezone} />
      </Popover>
    </>
  )
}

export default SensorView
