import { Box, CircularProgress, Divider, List, ListItem, Switch, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { createAlert, deleteAlert, fetchAlerts } from "../../../../network/network"
import { Alert } from "../../../../models/alert"
import AlertSettingSection from "./AlertSettingSection"
import { convertedDifferenceValue, convertedTemperatureValue } from "../../../../utilities/calculations"
import EmptyIconView from "../../../reusable/EmptyIconView"

interface Props {
  projectId: number
  placementId: number
}

const AlertsView = (props: Props) => {
  const loadedProjects = useAppSelector(state => state.project.projects)
  const selectedProject = loadedProjects.find(p => p.id === props.projectId)
  const selectedPlacement = selectedProject?.placements.find(p => p.id === props.placementId)
  const [alerts, setAlerts] = React.useState<Alert[]>([])
  const accessToken = useAppSelector(state => state.user.token)
  const [loading, setLoading] = React.useState(false)
  const [goalAlertLoading, setGoalAlertLoading] = React.useState(false)

  if (!selectedPlacement) {
    return (
      <></>
    )
  }

  useEffect(() => {
    const loadAlertsFromNetwork = async () => {
      if (!accessToken) {
        return
      }
      setLoading(true)
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      setAlerts(alerts)
      setLoading(false)
    }
    loadAlertsFromNetwork()
  }, [])

  if (loading) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="column"
        sx={{ padding: "12px" }}
      >
        <CircularProgress/>
      </Box>
    )
  }

  const minimumTempAlert = alerts.filter(a => a.type === "minimum_temp")
  const minimumApproachAlert = alerts.filter(a => a.type === "minimum_temp_approach")
  const maximumTempAlert = alerts.filter(a => a.type === "maximum_temp")
  const maximumApproachAlert = alerts.filter(a => a.type === "maximum_temp_approach")
  const tempDifferenceAlert = alerts.filter(a => a.type === "temperature_difference")
  const tempDifferenceApproachAlert = alerts.filter(a => a.type === "temperature_difference_approach")
  const goalReachedAlert = alerts.filter(a => a.type === "goal_reached")
  const goalReachedAlertEnabled = goalReachedAlert.length > 0
  const goalReachAlertId = goalReachedAlert.length > 0 ? goalReachedAlert[0].id : null

  const handleGoalAlertValueChange = async (_e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (!accessToken) {
      return
    }

    setGoalAlertLoading(true)

    try {
      if (value) {
        await createAlert(accessToken, props.projectId, props.placementId, "goal_reached", null)
      } else if (goalReachAlertId) {
        await deleteAlert(accessToken, props.projectId, props.placementId, goalReachAlertId)
      }
      
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      setAlerts(alerts)
    } catch (e) {
      console.log(e)
      alert("Failed to update alert")
    } finally {
      setGoalAlertLoading(false)
    }
  }

  const visibleAlertSecions = [
    "minimum_temp",
    "maximum_temp",
    "temperature_difference",
    "goal_reached"
  ].filter(section => {
    if (section === "minimum_temp") {
      return selectedPlacement.temperatureLimit.minimumTemperature !== null
    } else if (section === "maximum_temp") {
      return selectedPlacement.temperatureLimit.maximumTemperature !== null
    } else if (section === "temperature_difference") {
      return selectedPlacement.temperatureLimit.difference !== null
    } else if (section === "goal_reached") {
      return selectedPlacement.maturityMethod.type !== "disabled" && selectedPlacement.goals.length > 0
    }
    return
  })

  return (
    <Box display="flex" flexDirection="column" sx={{
      minHeight: "400px"
    }}>
      {visibleAlertSecions.length === 0 &&
        <Box sx={{
          marginTop: "20%",
        }}>
          <EmptyIconView backgroundColor="transparent" helperText="Once you set temperature limits or goals for this placement, you will be able to adjust alert settings." />
        </Box>
      }
      {visibleAlertSecions.map((section, index) => {
        return (
          <React.Fragment key={section}>
            {index > 0 &&
              <Divider sx={{
                marginTop: "12px"
              }} />
            }
            {section === "minimum_temp" &&
              <AlertSettingSection
                projectId={props.projectId}
                placementId={props.placementId}
                mainAlertId={minimumTempAlert.length > 0 ? minimumTempAlert[0].id : null}
                mainAlertType="minimum_temp"
                mainAlertTitle="Minimum Temperature"
                mainAlertEnabled={minimumTempAlert.length > 0}
                mainAlertDescription={`When any sensor in this project gets a reading below <b>${convertedTemperatureValue(selectedPlacement.temperatureLimit.minimumTemperature ?? 0, selectedPlacement.temperatureUnit).toFixed(1) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}</b>, an alert will be triggered`}
                secondaryAlertId={minimumApproachAlert.length > 0 ? minimumApproachAlert[0].id : null}
                secondaryAlertType="minimum_temp_approach"
                secondaryAlertTitle="Approach Warning"
                secondaryAlertEnabled={minimumApproachAlert.length > 0}
                secondaryAlertDescription="Trigger an alert when the minimum temperature in this placement gets within this value of the minimum temperature alert"
                secondaryAlertValue={minimumApproachAlert.length > 0 ? minimumApproachAlert[0].value : 0}
                secondaryAlertValueUnit={selectedPlacement.temperatureUnit}
                onChange={setAlerts}
              />
            }
            {section === "maximum_temp" &&
              <AlertSettingSection
                projectId={props.projectId}
                placementId={props.placementId}
                mainAlertId={maximumTempAlert.length > 0 ? maximumTempAlert[0].id : null}
                mainAlertType="maximum_temp"
                mainAlertTitle="Maximum Temperature"
                mainAlertEnabled={maximumTempAlert.length > 0}
                mainAlertDescription={`When any sensor in this placement gets a reading above ${convertedTemperatureValue(selectedPlacement.temperatureLimit.maximumTemperature ?? 0, selectedPlacement.temperatureUnit).toFixed(1) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}, an alert will be triggered`}
                secondaryAlertId={maximumApproachAlert.length > 0 ? maximumApproachAlert[0].id : null}
                secondaryAlertType="maximum_temp_approach"
                secondaryAlertTitle="Approach Warning"
                secondaryAlertEnabled={maximumApproachAlert.length > 0}
                secondaryAlertDescription="Trigger an alert when the maximum temperature in this placement gets within this value of the maximum temperature"
                secondaryAlertValue={maximumApproachAlert.length > 0 ? maximumApproachAlert[0].value : 0}
                secondaryAlertValueUnit={selectedPlacement.temperatureUnit}
                onChange={setAlerts}
              />
            }
            {section === "temperature_difference" &&
              <AlertSettingSection
                projectId={props.projectId}
                placementId={props.placementId}
                mainAlertId={tempDifferenceAlert.length > 0 ? tempDifferenceAlert[0].id : null}
                mainAlertType="temperature_difference"
                mainAlertTitle="Temperature Difference"
                mainAlertEnabled={tempDifferenceAlert.length > 0}
                mainAlertDescription={`When the temperature difference in this placement surpasses ${convertedDifferenceValue(selectedPlacement.temperatureLimit.difference ?? 0, selectedPlacement.temperatureUnit).toFixed(1) + `º${selectedPlacement.temperatureUnit.toUpperCase()}`}, an alert will be triggered`}
                secondaryAlertId={tempDifferenceApproachAlert.length > 0 ? tempDifferenceApproachAlert[0].id : null}
                secondaryAlertType="temperature_difference_approach"
                secondaryAlertTitle="Approach Warning"
                secondaryAlertEnabled={tempDifferenceApproachAlert.length > 0}
                secondaryAlertDescription="Trigger an alert when the temperature difference in this placement gets within this value of the temperature difference limit"
                secondaryAlertValue={tempDifferenceApproachAlert.length > 0 ? tempDifferenceApproachAlert[0].value : 0}
                secondaryAlertValueUnit={selectedPlacement.temperatureUnit}
                onChange={setAlerts}
              />
            }
            {section === "goal_reached" &&
              <List 
                sx={{
                  width: "100%",
                  padding: "0px",
                  margin: "0px"
                }}
              >
                <ListItem>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                    width: "100%",
                    padding: 0,
                    margin: "12px 0px 0px 0px"
                  }}>
                    <Typography variant="body1" sx={{fontWeight: "bold"}}>
                      Goal Reached
                    </Typography>
                    {goalAlertLoading &&
                      <Box display="flex" flexDirection="row" sx={{alignItems: "center", width: "26px", height: "26px"}}>
                        <CircularProgress size="18px"/>
                      </Box>
                    }
                    {!goalAlertLoading &&
                      <Switch key={`goal_reached-${goalReachedAlertEnabled}`} defaultChecked={goalReachedAlertEnabled} onChange={handleGoalAlertValueChange}/>
                    }
                  </Box>
                </ListItem>
              </List>
            }
          </React.Fragment>
        )}
      )}
    </Box>
  )
}

export default AlertsView
