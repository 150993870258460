import { convertedTemperatureValue } from "../utilities/calculations"
import CurveFit, { convertToCurveFitEntry, CurveFitEntry } from "./curvefit"
import SampleUnit from "./units"

export type ArrheniusParameters = {
  activationEnergy: number
  referenceTemperature: number
}

export type NurseSaulParameters = {
  datumTemperature: number
}

type MaturityMethod = {
  id: number
  type: "nurse saul" | "arrhenius" | "disabled"
  updatedAt: string
  ownerId: string
  temperatureUnit: SampleUnit
  parameters: ArrheniusParameters | NurseSaulParameters | null
  curveFit: CurveFit
}

export type MaturityParametersEntry = {
  activationEnergy: number | null
  referenceTemperature: number | null
  datumTemperature: number | null
}

export type MaturityMethodEntry = {
  type: MaturityMethod["type"]
  temperatureUnit: SampleUnit | ""
  parameters: MaturityParametersEntry
  curveFit: CurveFitEntry
}

export const isMaturityMethodEntryEqual = (a: MaturityMethodEntry, b: MaturityMethodEntry) => {
  return a.type === b.type
    && a.temperatureUnit === b.temperatureUnit
    && a.parameters.activationEnergy === b.parameters.activationEnergy
    && a.parameters.referenceTemperature === b.parameters.referenceTemperature
    && a.parameters.datumTemperature === b.parameters.datumTemperature
}

export const convertToMaturityMethodEntry = (maturityMethod: MaturityMethod): MaturityMethodEntry => {
  return {
    type: maturityMethod.type,
    temperatureUnit: maturityMethod.temperatureUnit,
    parameters: {
      activationEnergy: (maturityMethod.parameters as ArrheniusParameters)?.activationEnergy,
      referenceTemperature: (maturityMethod.parameters as ArrheniusParameters)?.referenceTemperature && convertedTemperatureValue((maturityMethod.parameters as ArrheniusParameters)?.referenceTemperature, maturityMethod.temperatureUnit),
      datumTemperature: (maturityMethod.parameters as NurseSaulParameters)?.datumTemperature && convertedTemperatureValue((maturityMethod.parameters as NurseSaulParameters)?.datumTemperature, maturityMethod.temperatureUnit),
    },
    curveFit: convertToCurveFitEntry(maturityMethod.curveFit, maturityMethod.temperatureUnit, maturityMethod.type)
  }
}

export default MaturityMethod