import { FormControl, InputLabel, List, ListItem, NativeSelect, TextField } from "@mui/material"
import React from "react"
import { PlacementEntry } from "../../../models/placement"

interface Props {
  entry: PlacementEntry
  onEntryChange: (entry: PlacementEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const timeZones = Intl.supportedValuesOf("timeZone")

const PlacementGeneralInfoView = (props: Props) => {
  return (
    <List sx={{
      marginTop: "24px"
    }}>
      <ListItem sx={{
        paddingLeft: "0px",
        paddingRight: "0px"
      }}>
        <TextField
          label="Placement Name"
          value={props.entry.name}
          placeholder="Enter placement name"
          onChange={(e) => {
            props.onEntryChange({
              ...props.entry,
              name: e.target.value
            })
          }}
          fullWidth
          disabled={props.disabled}
          InputLabelProps={{
            shrink: true
          }}
        />
      </ListItem>
      <ListItem sx={{
        paddingLeft: "0px",
        paddingRight: "0px"
      }}>
        <FormControl fullWidth>
          <InputLabel sx={{
            transform: "translate(0, 4px) scale(0.75)"
          }} variant="standard" htmlFor="uncontrolled-native">
            Timezone
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: "timeZone",
              id: "uncontrolled-native",
            }}
            sx={{
              padding: "6px 0px",
              marginTop: "6px",
              marginBottom: "6px"
            }}
            value={props.entry.timeZone}
            onChange={(e) => {
              props.onEntryChange({
                ...props.entry,
                timeZone: e.target.value
              })
            }}
            fullWidth
            disabled={props.disabled}
          >
            {
              timeZones.map((tz) => (
                <option key={tz} value={tz}>{tz}</option>
              ))
            }
          </NativeSelect>
        </FormControl>
      </ListItem>
    </List>
  )
}

export default PlacementGeneralInfoView