import { Box, CircularProgress, List, ListItem, Switch, TextField, Typography } from "@mui/material"
import React from "react"
import { createAlert, deleteAlert, fetchAlerts } from "../../../../network/network"
import { useAppSelector } from "../../../../store/hooks"
import { Alert } from "../../../../models/alert"
import SampleUnit from "../../../../models/units"
import { convertedDifferenceValue } from "../../../../utilities/calculations"

interface Props {
  projectId: number
  placementId: number

  mainAlertId: number | null
  mainAlertType: Alert["type"]
  mainAlertTitle: string
  mainAlertEnabled: boolean
  mainAlertDescription: string

  secondaryAlertId: number | null
  secondaryAlertType: Alert["type"]
  secondaryAlertTitle: string
  secondaryAlertEnabled: boolean
  secondaryAlertDescription: string
  secondaryAlertValue: number | null
  secondaryAlertValueUnit: SampleUnit

  onChange: (alerts: Alert[]) => void
}

const AlertSettingSection = (props: Props) => {
  const accessToken = useAppSelector(state => state.user.token)
  const [mainAlertLoading, setMainAlertLoading] = React.useState(false)
  const [secondaryAlertLoading, setSecondaryAlertLoading] = React.useState(false)

  const handleMainAlertChange = async (_e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (!accessToken) {
      return
    }

    setMainAlertLoading(true)

    try {
      if (value) {
        await createAlert(accessToken, props.projectId, props.placementId, props.mainAlertType, null)
      } else if (props.mainAlertId) {
        await deleteAlert(accessToken, props.projectId, props.placementId, props.mainAlertId)
      }
      
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      props.onChange(alerts)
    } catch (e) {
      console.log(e)
      alert("Failed to update alert")
    } finally {
      setMainAlertLoading(false)
    }
  }

  const handleSecondaryAlertChange = async (_e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (!accessToken) {
      return
    }

    setSecondaryAlertLoading(true)

    try {
      if (value) {
        await createAlert(accessToken, props.projectId, props.placementId, props.secondaryAlertType, 5)
      } else if (props.secondaryAlertId) {
        await deleteAlert(accessToken, props.projectId, props.placementId, props.secondaryAlertId)
      }
      
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      props.onChange(alerts)
    } catch (e) {
      console.log(e)
      alert("Failed to update alert")
    } finally {
      setSecondaryAlertLoading(false)
    }
  }

  const handleSecondaryAlertValueChange = async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!accessToken) {
      return
    }

    setSecondaryAlertLoading(true)

    let tempValue = parseFloat(e.target.value)

    if (isNaN(tempValue)) {
      tempValue = props.secondaryAlertValueUnit === "c" ? 5 : 9
    }

    const celsiusValue = convertedDifferenceValue(tempValue, "c", props.secondaryAlertValueUnit)

    try {
      await createAlert(accessToken, props.projectId, props.placementId, props.secondaryAlertType, celsiusValue)
      
      const alerts = await fetchAlerts(accessToken, props.projectId, props.placementId)
      props.onChange(alerts)
    } catch (e) {
      console.log(e)
      alert("Failed to update alert")
    } finally {
      setSecondaryAlertLoading(false)
    }
  }

  return (
    <List sx={{
      padding: "0px 0px",
      marginTop: "12px",
    }}>
      <ListItem sx={{
        width: "100%"
      }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
          width: "100%",
          padding: 0,
          margin: 0,
          alignItems: "center"
        }}>
          <Typography variant="body1" sx={{fontWeight: "bold"}}>
            {props.mainAlertTitle}
          </Typography>
          {mainAlertLoading &&
            <Box display="flex" flexDirection="row" sx={{alignItems: "center", width: "26px", height: "26px"}}>
              <CircularProgress size="18px"/>
            </Box>
          }
          {!mainAlertLoading &&
            <Switch key={`${props.mainAlertTitle}-${props.mainAlertEnabled}`} defaultChecked={props.mainAlertEnabled} onChange={handleMainAlertChange}/>
          }
        </Box>
      </ListItem>
      {props.mainAlertEnabled &&
        <>
          <ListItem sx={{
            width: "100%"
          }}>
            <Typography variant="body2" sx={{
              maxWidth: "100%"
            }}>
              <div dangerouslySetInnerHTML={{__html: props.mainAlertDescription}} />
            </Typography>
          </ListItem>
          <ListItem>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
              width: "100%",
              padding: 0,
              margin: 0
            }}>
              <Typography variant="body1" sx={{fontWeight: "bold"}}>
                {props.secondaryAlertTitle}
              </Typography>
              {secondaryAlertLoading &&
                <Box display="flex" flexDirection="row" sx={{alignItems: "center", width: "26px", height: "26px"}}>
                  <CircularProgress size="18px"/>
                </Box>
              }
              {!secondaryAlertLoading &&
                <Switch key={`${props.secondaryAlertTitle}-${props.secondaryAlertEnabled}`} defaultChecked={props.secondaryAlertEnabled} onChange={handleSecondaryAlertChange}/>
              }
            </Box>
          </ListItem>
          {props.secondaryAlertEnabled &&
            <>
              <ListItem sx={{
                width: "100%"
              }}>
                <Typography variant="body2" sx={{
                  maxWidth: "100%"
                }}>
                  {props.secondaryAlertDescription}
                </Typography>
              </ListItem>
              <ListItem sx={{
                width: "100%"
              }}>
                <TextField
                  label={`º${props.secondaryAlertValueUnit.toUpperCase()}`}
                  type="number"
                  placeholder="Required"
                  defaultValue={props.secondaryAlertValue && convertedDifferenceValue(props.secondaryAlertValue, props.secondaryAlertValueUnit).toFixed(1)}
                  onBlur={handleSecondaryAlertValueChange}
                  sx={{
                    width: "100%",
                    marginTop: "8px"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </ListItem>
            </>
          }
        </>
      }
    </List>
  )
}

export default AlertSettingSection