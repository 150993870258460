import { FormControl, InputLabel, List, ListItem, NativeSelect, TextField, Typography } from "@mui/material"
import React from "react"
import SampleUnit from "../../../models/units"
import { convertedDifferenceValue, convertedTemperatureValue } from "../../../utilities/calculations"
import { PlacementEntry } from "../../../models/placement"

interface Props {
  entry: PlacementEntry
  onEntryChange: (entry: PlacementEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

export const floatOrNull = (value: string) => {
  const parsed = parseFloat(value)
  return isNaN(parsed) ? null : parsed
}

const TemperatureLimitsView = (props: Props) => {
  const sanitizeTemperature = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!props.entry.temperatureUnit) {
      e.target.value = ""
      return
    }

    e.target.value = convertedTemperatureValue(parseFloat(e.target.value), props.entry.temperatureUnit, props.entry.temperatureUnit).toFixed(1)

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeTemperatureDifference = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (!props.entry.temperatureUnit) {
      e.target.value = ""
      return
    }

    e.target.value = convertedDifferenceValue(parseFloat(e.target.value), props.entry.temperatureUnit, props.entry.temperatureUnit).toFixed(1)

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  return (
    <>
      <Typography variant="caption">
        Temperature Limits
      </Typography>
      <List sx={{
        marginTop: "12px"
      }}>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <FormControl fullWidth error={props.highlightRequiredFields && props.entry.temperatureUnit === ""}>
            <InputLabel sx={{
              transform: "translate(0, 4px) scale(0.75)"
            }} variant="standard" htmlFor="uncontrolled-native-tu">
              Temprature Unit
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: "temperatureUnit",
                id: "uncontrolled-native-tu",
              }}
              sx={{
                padding: "6px 0px",
                marginTop: "6px",
                marginBottom: "6px"
              }}
              value={props.entry.temperatureUnit}
              onChange={(e) => {
                props.onEntryChange({
                  ...props.entry,
                  temperatureUnit: e.target.value as SampleUnit
                })
              }}
              fullWidth
              disabled={props.disabled}
            >
              {props.entry.temperatureUnit === "" &&
                <option value="" disabled>Select Unit</option>
              }
              <option value="c">ºC</option>
              <option value="f">ºF</option>
            </NativeSelect>
          </FormControl>
        </ListItem>
        { props.entry.temperatureUnit &&
          <>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <TextField
                key={`minimumTemperature-${props.entry.temperatureUnit}`}
                label={`Minimum Temp (${props.entry.temperatureUnit === "c" ? "ºC" : "ºF"})`}
                defaultValue={props.entry.temperatureLimit.minimumTemperature?.toFixed(1)}
                placeholder="Not Set"
                onBlur={(e) => {
                  sanitizeTemperature(e)

                  props.onEntryChange({
                    ...props.entry,
                    temperatureLimit: {
                      ...props.entry.temperatureLimit,
                      minimumTemperature: floatOrNull(e.target.value)
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </ListItem>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <TextField
                key={`maximumTemperature-${props.entry.temperatureUnit}`}
                label={`Maximum Temp (${props.entry.temperatureUnit === "c" ? "ºC" : "ºF"})`}
                defaultValue={props.entry.temperatureLimit.maximumTemperature?.toFixed(1)}
                placeholder="Not Set"
                onBlur={(e) => {
                  sanitizeTemperature(e)

                  props.onEntryChange({
                    ...props.entry,
                    temperatureLimit: {
                      ...props.entry.temperatureLimit,
                      maximumTemperature: floatOrNull(e.target.value)
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </ListItem>
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px"
            }}>
              <TextField
                key={`difference-${props.entry.temperatureUnit}`}
                label={`Temp Difference (${props.entry.temperatureUnit === "c" ? "ºC" : "ºF"})`}
                defaultValue={props.entry.temperatureLimit.difference?.toFixed(1)}
                placeholder="Not Set"
                onBlur={(e) => {
                  sanitizeTemperatureDifference(e)

                  props.onEntryChange({
                    ...props.entry,
                    temperatureLimit: {
                      ...props.entry.temperatureLimit,
                      difference: floatOrNull(e.target.value)
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </ListItem>
          </>
        }
      </List>
    </>
  )
}

export default TemperatureLimitsView
