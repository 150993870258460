import SampleUnit from "./units"
import { convertedDifferenceValue, convertedTemperatureValue } from "../utilities/calculations"

type TemperatureLimit = {
  id: number
  difference: number | null
  maximumTemperature: number | null
  minimumTemperature: number | null
  ownerId: string
}

export default TemperatureLimit

export type TemperatureLimitEntry = {
  difference: number | null
  maximumTemperature: number | null
  minimumTemperature: number | null
}

export const isTemperatureLimitEntryEqual = (a: TemperatureLimitEntry, b: TemperatureLimitEntry): boolean => {
  return a.difference === b.difference
    && a.maximumTemperature === b.maximumTemperature
    && a.minimumTemperature === b.minimumTemperature
}

export const convertToTemperatureLimitEntry = (temperatureLimit: TemperatureLimit, temperatureUnit: SampleUnit): TemperatureLimitEntry => {
  return {
    difference: temperatureLimit.difference && convertedDifferenceValue(temperatureLimit.difference, temperatureUnit),
    maximumTemperature: temperatureLimit.maximumTemperature && convertedTemperatureValue(temperatureLimit.maximumTemperature, temperatureUnit),
    minimumTemperature: temperatureLimit.minimumTemperature && convertedTemperatureValue(temperatureLimit.minimumTemperature, temperatureUnit)
  }
}

