import React, { useEffect, useState } from "react"
import Sensor, { displayNameForSensor } from "../../models/sensor"
import Placement from "../../models/placement"
import { maturityForSensor } from "../../utilities/maturity"
import "chartjs-adapter-moment"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js"
import { Line } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import moment from "moment"
import { elapsedTimeTooltip } from "./tooltips"

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

interface Props {
  sensors: Sensor[]
  placement: Placement
  colorMap: Map<number, string>
  startDate?: moment.Moment
  endDate?: moment.Moment
}

const MaturityChart = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const annotations: { [name: string] : any } = {}
  props.placement.goals.forEach((goal) => {
    if (goal.enabled === false) {
      return
    }

    const value = props.placement.maturityMethod.temperatureUnit === "c" || props.placement.maturityMethod.type === "arrhenius" ? goal.value : goal.value * 9/5

    annotations[goal.name] = {
      type: "line",
      mode: "horizontal",
      scaleID: "y",
      value: value,
      borderColor: "black",
      borderWidth: 2,
      borderDash: [6, 6],
      label: {
        color: "black",
        content: goal.name,
        display: true,
        backgroundColor: "#fff0",
        position: "end" as const,
        yAdjust: -10
      }
    }
  })

  let maturityUnit = ""
  if (props.placement.maturityMethod.type === "arrhenius") {
    maturityUnit = `º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}`
  } else {
    maturityUnit = `º${props.placement.maturityMethod.temperatureUnit.toUpperCase()}-hr`
  }

  const options = {
    animation: {
      duration: 0
    },
    responsive: true,
    spansGaps: false,
    maintainAspectRatio: false,
    pointRadius: 1,
    plugins: {
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: (context: any) => {
            return moment(context[0].parsed.x).format("MM/DD/YY H:mm")
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: (context: any) => {
            return `${context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 0})} ${props.placement.maturityMethod.type === "arrhenius" ? "hr" : maturityUnit}`
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          footer: (tooltipItems: any) => {
            const sensorName = tooltipItems[0].dataset.label
            const sensor = props.sensors.find(sensor => displayNameForSensor(sensor) === sensorName)
            if (!sensor) {
              return ""
            }

            return elapsedTimeTooltip(tooltipItems, moment(tooltipItems[0].raw.x), moment(new Date(sensor.placementDate).setSeconds(0, 0)))
          }
        }
      },
      legend: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (e: any) => e.stopPropagation(),
        display: true
      },
      title: {
        display: true,
        text: "Maturity"
      },
      annotation: {
        annotations: annotations
      }
    },
    scales: {
      x: {
        offset: true,
        type: "time" as const,
        time: {
          displayFormats: {
            millisecond: "MM/DD/YY H:mm",
            second: "MM/DD/YY H:mm",
            minute: "MM/DD/YY H:mm",
            hour: "MM/DD/YY H:mm",
            day: "MM/DD/YY H:mm",
            week: "MM/DD/YY H:mm",
            month: "MM/DD/YY H:mm",
            quarter: "MM/DD/YY H:mm",
            year: "MM/DD/YY H:mm"
          }
        },
        gridLines: {
          display: true
        },
        ticks: {
          maxTicksLimit: 6
        }
      }, 
      y: {
        ticks: {
          callback: function(label: string | number | undefined) {
            if (typeof label !== "number") { return "" }

            if (props.placement.maturityMethod.type === "arrhenius") {
              return label.toLocaleString(undefined, {maximumFractionDigits: 0}) + " hr"
            } else {
              return label.toLocaleString(undefined, {maximumFractionDigits: 0}) + " º" + props.placement.maturityMethod.temperatureUnit.toUpperCase() + "-hr"
            }
          }
        }
      }
    }
  }

  const dataForSensor = (sensor: Sensor) => {
    const maturity = maturityForSensor(sensor, props.placement, props.startDate?.toDate(), props.endDate?.toDate())
    return maturity.map((maturity) => {
      return {
        x: maturity.sample.time.toDate(),
        y: maturity.maturityValue
      }
    })
  }

  const [data, setData] = useState<{
    datasets: {
      label: string | undefined
      data: { x: Date, y: number }[]
      borderColor: string | undefined
      backgroundColor: string | undefined
      borderWidth: number | undefined
    }[]
  }>({datasets: []})

  useEffect(() => {
    const calculatedData = {
      datasets: props.sensors.map((sensor: Sensor) => {
        return {
          label: displayNameForSensor(sensor),
          data: dataForSensor(sensor),
          borderColor: props.colorMap.get(sensor.id),
          backgroundColor: props.colorMap.get(sensor.id),
          borderWidth: 1
        }
      })
    }
    setData(calculatedData)
  }, [props.sensors, props.startDate, props.endDate])

  return (
    <Line data={data} options={options} width="100%" height="100%" />
  )
}

export default MaturityChart
