import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { createRecipient } from "../../../../network/network"
import { AlertRecipient } from "../../../../models/alertrecipient"
import { Box, IconButton, InputAdornment, Tab, Tabs, TextField, Typography } from "@mui/material"
import { ChatBubbleOutline, Close, MailOutline } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { PatternFormat } from "react-number-format"

interface Props {
  projectId: number
  placementId: number
  existingRecipients: AlertRecipient[]
  onClose: () => void
  onRecipientAdded: (recipient: AlertRecipient) => void
}

const AddRecipientView = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const accessToken = useAppSelector(state => state.user.token)
  const [name, setName] = useState<string>("")
  const [type, setType] = useState<AlertRecipient["type"]>("email")
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string | null>(null)


  const handleAddRecipient = async () => {
    if (!accessToken) {
      return
    }

    const santizedPhone = phone.replaceAll("_", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "")

    if (name === "") {
      setErrorMessage("Name is required")
      return
    }

    if (type === "email" && !email) {
      setErrorMessage("Email is required")
      return
    }

    if (type === "sms" && !phone) {
      setErrorMessage("Phone number is required")
      return
    }

    if (type === "sms" && santizedPhone.length < 12) {
      setErrorMessage("Phone number must be at least 10 digits")
      return
    }

    if (type === "email" && !email.includes("@")) {
      setErrorMessage("Email must be valid")
      return
    }

    if (props.existingRecipients.find(r => {
      return (type === "email" && r.contact.toLowerCase() === email.toLowerCase()) || (type === "sms" && r.contact === santizedPhone)
    })) {
      setErrorMessage("Recipient already exists")
      return
    }
    
    setErrorMessage(null)
    setLoading(true)

    try {
      const newRecipient = await createRecipient(accessToken, props.projectId, props.placementId, name, type === "email" ? email : santizedPhone, type)
      props.onRecipientAdded(newRecipient)
    } catch (error) {
      console.error(error)
      setErrorMessage("Failed to add recipient")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [type])

  return (
    <Box sx={{
      padding: "16px",
      width: "300px"
    }}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="h5" sx={{
          width: "100%"
        }}>
          Add a Recipient
        </Typography>
        <IconButton sx={{
          margin: "-8px"
        }} onClick={props.onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ textAlign: "center", maxWidth: "400px", marginTop: "24px" }}>
        <Tabs
          variant="fullWidth" 
          value={type} 
          onChange={(event, newValue) => setType(newValue)}
        >
          <Tab label="Email" value="email" disabled={loading}/>
          <Tab label="SMS" value="sms" disabled={loading}/>
        </Tabs>
      </Box>
      <TextField
        label="Name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        placeholder="Required"
        sx={{ width: "100%", marginTop: "24px" }}
        InputLabelProps={{
          shrink: true
        }}
        disabled={loading}
      />
      {type === "sms" &&
        <PatternFormat format="+1 (###) ### ####" allowEmptyFormatting mask="_"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          customInput={TextField}
          label="Phone Number"
          placeholder="Required"
          sx={{ width: "100%", marginTop: "24px" }}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChatBubbleOutline/>
              </InputAdornment>
            )
          }}
          disabled={loading}
        />
      }
      {type === "email" &&
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          label={type === "email" ? "Email" : "Phone Number"}
          placeholder="Required"
          sx={{ width: "100%", marginTop: "24px" }}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutline/>
              </InputAdornment>
            )
          }}
          disabled={loading}
        />
      }
      {errorMessage &&
        <Typography variant="body1" color="error" sx={{ marginTop: "12px", textAlign: "center" }}>
          {errorMessage}
        </Typography>
      }
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={handleAddRecipient}
        sx={{ width: "100%", marginTop: "36px" }}
      >
        Add
      </LoadingButton>
    </Box>
  )
}

export default AddRecipientView