import { isCurveFitEntryEqual } from "./curvefit"
import { convertToGoalEntry, Goal, GoalEntry, isGoalEntryEqual } from "./goal"
import MaturityMethod, { convertToMaturityMethodEntry, isMaturityMethodEntryEqual, MaturityMethodEntry } from "./maturitymethod"
import Sensor from "./sensor"
import TemperatureLimit, { convertToTemperatureLimitEntry, isTemperatureLimitEntryEqual, TemperatureLimitEntry } from "./temperaturelimit"
import SampleUnit from "./units"

type Placement = {
  id: number
  description: string
  name: string
  updatedAt: string
  timeZone: string
  isArchived: boolean
  sensors: Sensor[]
  projectId: number
  temperatureUnit: SampleUnit
  temperatureLimit: TemperatureLimit
  maturityMethod: MaturityMethod
  goals: Goal[]
}

export type PlacementEntry = {
  name: string | null
  timeZone: string
  temperatureUnit: SampleUnit | ""
  temperatureLimit: TemperatureLimitEntry
  maturityMethod: MaturityMethodEntry
  goals: GoalEntry[]
}

export const convertToPlacementEntry = (placement: Placement): PlacementEntry => {
  const maturityMethodEntry = convertToMaturityMethodEntry(placement.maturityMethod)
  
  return {
    name: placement.name,
    timeZone: placement.timeZone,
    temperatureUnit: placement.temperatureUnit,
    temperatureLimit: convertToTemperatureLimitEntry(placement.temperatureLimit, placement.temperatureUnit),
    maturityMethod: maturityMethodEntry,
    goals: placement.goals.map(goal => {
      return convertToGoalEntry(goal, maturityMethodEntry)
    })
  }
}

export const isPlacementEntryEqual = (a: PlacementEntry, b: PlacementEntry) => {
  return a.name === b.name
    && a.timeZone === b.timeZone
    && a.temperatureUnit === b.temperatureUnit
    && isTemperatureLimitEntryEqual(a.temperatureLimit, b.temperatureLimit)
    && isMaturityMethodEntryEqual(a.maturityMethod, b.maturityMethod)
    && isCurveFitEntryEqual(a.maturityMethod.curveFit, b.maturityMethod.curveFit)
    && a.goals.length === b.goals.length
    && a.goals.every((goal, index) => isGoalEntryEqual(goal, b.goals[index]))
}

export default Placement