import { FormControl, InputLabel, List, ListItem, NativeSelect, Typography } from "@mui/material"
import React from "react"
import MaturityMethod, { MaturityMethodEntry } from "../../../models/maturitymethod"
import HyperbolicDataView from "./curvefit/HyperbolicDataView"
import LogarithmicDataView from "./curvefit/LogarithmicDataView"
import { CurveFitEntry } from "../../../models/curvefit"

interface Props {
  maturityMethod: MaturityMethodEntry
  entry: CurveFitEntry
  onEntryChange: (entry: CurveFitEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const CurveFitView = (props: Props) => {
  if (props.maturityMethod.type === "disabled") {
    return <></>
  }

  return (
    <>
      <Typography variant="caption">
        Curve Fit
      </Typography>
      <Typography sx={{
        fontSize: "10px",
        color: "gray"
      }}>
        Values below can be found on the maturity report
      </Typography>
      <List sx={{
        marginTop: "12px"
      }}>
        <ListItem sx={{
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
          <FormControl fullWidth>
            <InputLabel sx={{
              transform: "translate(0, 4px) scale(0.75)"
            }} variant="standard" htmlFor="uncontrolled-native-cf">
              Curve-fit Model
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: "curveFit",
                id: "uncontrolled-native-cf",
              }}
              sx={{
                padding: "6px 0px",
                marginTop: "6px",
                marginBottom: "6px"
              }}
              value={props.entry.type}
              onChange={(e) => {
                props.onEntryChange({
                  ...props.entry,
                  type: e.target.value as MaturityMethod["curveFit"]["type"],
                  parameters: {
                    ...props.entry.parameters,
                    slope: null,
                    intercept: null,
                    ultimateStrength: null,
                    kValue: null,
                    initialEquivalentAge: null,
                    initialMaturity: null,
                    strengthUnit: props.maturityMethod.curveFit.parameters.strengthUnit ?? "mpa"
                  }
                })
              }}
              fullWidth
              disabled={props.disabled}
            >              
              <option value="disabled">Disabled</option>
              <option value="hyperbolic">Hyperbolic</option>
              { props.maturityMethod.type === "nurse saul" &&
                <>
                  <option value="log">Log</option>
                  <option value="ln">LN</option>
                </>
              }
            </NativeSelect>
          </FormControl>
        </ListItem>

        {props.entry.type == "hyperbolic" &&
          <HyperbolicDataView maturityMethod={props.maturityMethod} entry={props.entry} onEntryChange={props.onEntryChange} disabled={props.disabled} highlightRequiredFields={props.highlightRequiredFields} />
        }
        {(props.entry.type == "log" || props.entry.type == "ln") &&
          <LogarithmicDataView maturityMethod={props.maturityMethod} entry={props.entry} onEntryChange={props.onEntryChange} disabled={props.disabled} highlightRequiredFields={props.highlightRequiredFields} />
        }
      </List>
    </>
  )
}

export default CurveFitView
