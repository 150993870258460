import { convertCurveFitIntercept, convertInitialMaturityValue, convertKValue, convertSlope, convertUltimateStrengthValue } from "../utilities/calculations"
import MaturityMethod from "./maturitymethod"
import SampleUnit from "./units"

export type LogarithmicParameters = {
  strengthUnit: UltimateStrengthUnit
  slope: number
  intercept: number
}

export type UltimateStrengthUnit = "psi" | "mpa"

export type HyperbolicParameters = {
  strengthUnit: UltimateStrengthUnit
  initialMaturity: number
  kValue: number
  ultimateStrength: number
  initialEquivalentAge: number
}

export type CurveFitType = "disabled" | "hyperbolic" | "log" | "ln"

type CurveFit = {
  id: number
  type: CurveFitType
  updatedAt: string
  parameters: HyperbolicParameters | LogarithmicParameters | null
  ownerId: string
}

export type CurveFitParametersEntry = {
  strengthUnit: UltimateStrengthUnit | ""
  slope: number | null
  intercept: number | null
  initialMaturity: number | null
  kValue: number | null
  ultimateStrength: number | null
  initialEquivalentAge: number | null
}

export type CurveFitEntry = {
  type: CurveFitType
  parameters: CurveFitParametersEntry
}

export const isCurveFitEntryEqual = (a: CurveFitEntry, b: CurveFitEntry) => {
  return a.type === b.type
    && a.parameters.strengthUnit === b.parameters.strengthUnit
    && a.parameters.slope === b.parameters.slope
    && a.parameters.intercept === b.parameters.intercept
    && a.parameters.initialMaturity === b.parameters.initialMaturity
    && a.parameters.kValue === b.parameters.kValue
    && a.parameters.ultimateStrength === b.parameters.ultimateStrength
    && a.parameters.initialEquivalentAge === b.parameters.initialEquivalentAge
}

export const convertToCurveFitEntry = (curveFit: CurveFit, temperatureUnit: SampleUnit, maturityMethodType: MaturityMethod["type"]): CurveFitEntry => {
  if (maturityMethodType === "disabled") {
    return {
      type: "disabled",
      parameters: {
        strengthUnit: null,
        slope: null,
        intercept: null,
        initialMaturity: null,
        kValue: null,
        ultimateStrength: null,
        initialEquivalentAge: null
      }
    }
  } else if (maturityMethodType === "nurse saul") {
    return {
      type: curveFit.type,
      parameters: {
        slope: (curveFit.parameters as LogarithmicParameters)?.slope && convertSlope((curveFit.parameters as LogarithmicParameters)?.slope, curveFit.parameters?.strengthUnit ?? "mpa"),
        intercept: (curveFit.parameters as LogarithmicParameters)?.intercept && convertCurveFitIntercept((curveFit.parameters as LogarithmicParameters)?.intercept, temperatureUnit, (curveFit.parameters as LogarithmicParameters)?.strengthUnit, curveFit.type, (curveFit.parameters as LogarithmicParameters)?.slope),
        ultimateStrength: (curveFit.parameters as HyperbolicParameters)?.strengthUnit && convertUltimateStrengthValue((curveFit.parameters as HyperbolicParameters)?.ultimateStrength, curveFit.parameters?.strengthUnit ?? "mpa"),
        strengthUnit: (curveFit.parameters as HyperbolicParameters)?.strengthUnit,
        initialMaturity: (curveFit.parameters as HyperbolicParameters)?.initialMaturity && convertInitialMaturityValue((curveFit.parameters as HyperbolicParameters)?.initialMaturity, temperatureUnit),
        kValue: (curveFit.parameters as HyperbolicParameters)?.kValue && convertKValue((curveFit.parameters as HyperbolicParameters)?.kValue, temperatureUnit),
        initialEquivalentAge: null
      }
    }
  } else {
    return {
      type: curveFit.type,
      parameters: {
        slope: (curveFit.parameters as LogarithmicParameters)?.slope && convertSlope((curveFit.parameters as LogarithmicParameters)?.slope, curveFit.parameters?.strengthUnit ?? "mpa"),
        intercept: (curveFit.parameters as LogarithmicParameters)?.intercept && convertCurveFitIntercept((curveFit.parameters as LogarithmicParameters)?.intercept, temperatureUnit, (curveFit.parameters as LogarithmicParameters)?.strengthUnit, curveFit.type, (curveFit.parameters as LogarithmicParameters)?.slope),
        ultimateStrength: (curveFit.parameters as HyperbolicParameters)?.strengthUnit && convertUltimateStrengthValue((curveFit.parameters as HyperbolicParameters)?.ultimateStrength, curveFit.parameters?.strengthUnit ?? "mpa"),
        strengthUnit: (curveFit.parameters as HyperbolicParameters)?.strengthUnit,
        initialMaturity: null,
        kValue: (curveFit.parameters as HyperbolicParameters)?.kValue,
        initialEquivalentAge: (curveFit.parameters as HyperbolicParameters)?.initialEquivalentAge
      }
    }
  }
}

export default CurveFit