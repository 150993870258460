import React, { useState } from "react"
import Placement, { convertToPlacementEntry, PlacementEntry } from "../../models/placement"
import PlacementSpecForm from "./specifications/PlacementSpecsForm"
import SampleUnit from "../../models/units"
import { TemperatureLimitEntry } from "../../models/temperaturelimit"
import MaturityMethod, { MaturityParametersEntry } from "../../models/maturitymethod"
import { CurveFitEntry, CurveFitParametersEntry } from "../../models/curvefit"
import Project from "../../models/project"
import { GoalEntry } from "../../models/goal"

interface Props {
  project: Project
  isLoading: boolean
  hasError: boolean
  onSave: (
    name: string | null, 
    timeZone: string, 
    temperatureUnit: SampleUnit, 
    temperatureLimit: TemperatureLimitEntry,
    maturityMethodType: MaturityMethod["type"],
    maturityMethodTemperatureUnit: SampleUnit,
    maturityMethod: MaturityParametersEntry,
    curveFitType: CurveFitEntry["type"],
    curveFit: CurveFitParametersEntry,
    goalsEntries: GoalEntry[]
  ) => void
  onClose: () => void
}

const getEntryFromPlacement = (placement: Placement | null): PlacementEntry => {
  if (!placement) {
    return {
      name: "",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      temperatureUnit: "",
      temperatureLimit: {
        minimumTemperature: null,
        maximumTemperature: null,
        difference: null
      },
      maturityMethod: {
        type: "disabled",
        temperatureUnit: "",
        parameters: {
          activationEnergy: null,
          referenceTemperature: null,
          datumTemperature: null
        },
        curveFit: {
          type: "disabled",
          parameters: {
            intercept: null,
            kValue: null,
            slope: null,
            ultimateStrength: null,
            initialMaturity: null,
            strengthUnit: "",
            initialEquivalentAge: null
          }
        }
      },
      goals: []
    }
  }

  const entry = convertToPlacementEntry(placement)
  entry.name = ""
  return entry
}

const mostRecentPlacement = (project: Project): Placement | null => {
  if (project.placements.length === 0) {
    return null
  }

  return project.placements.reduce((mostRecent, placement) => {
    if (!mostRecent || new Date(placement.updatedAt) > new Date(mostRecent.updatedAt)) {
      return placement
    }
    return mostRecent
  }, project.placements[0])
}

const NewPlacementView = (props: Props) => {
  const [entry, setEntry] = useState<PlacementEntry>(getEntryFromPlacement(mostRecentPlacement(props.project)))

  return (
    <PlacementSpecForm entry={entry} onEntryChange={setEntry} onSave={props.onSave} isLoading={props.isLoading} hasError={props.hasError} onClose={props.onClose}/>
  )
}

export default NewPlacementView