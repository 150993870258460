import { FormControl, InputLabel, ListItem, NativeSelect, TextField } from "@mui/material"
import React from "react"
import { CurveFitEntry, UltimateStrengthUnit } from "../../../../models/curvefit"
import { MaturityMethodEntry } from "../../../../models/maturitymethod"
import { floatOrNull } from "../TemperatureLimitsView"

interface Props {
  maturityMethod: MaturityMethodEntry
  entry: CurveFitEntry
  onEntryChange: (entry: CurveFitEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const LogarithmicDataView = (props: Props) => {
  if (!props.maturityMethod.temperatureUnit) {
    return <></>
  }

  const sanitizeIntercept = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (props.entry.parameters.strengthUnit === "mpa") {
      e.target.value = parseFloat(e.target.value).toFixed(2)
    } else {
      e.target.value = `${Math.round(parseFloat(e.target.value))}`
    }

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeSlope = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    if (props.entry.parameters.strengthUnit === "mpa") {
      e.target.value = parseFloat(e.target.value).toFixed(2)
    } else {
      e.target.value = parseFloat(e.target.value).toFixed(1)
    }

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  let defaultInterceptValue
  if (props.entry.parameters.strengthUnit === "mpa") {
    defaultInterceptValue = props.entry.parameters.intercept?.toFixed(2)
  } else if (props.entry.parameters.intercept) {
    defaultInterceptValue = `${Math.round(props.entry.parameters.intercept)}`
  }

  const strengthUnitDisplay = props.entry.parameters.strengthUnit === "mpa" ? "MPa" : "psi"

  let defaultSlopeValue
  if (props.entry.parameters.strengthUnit === "mpa") {
    defaultSlopeValue = props.entry.parameters.slope?.toFixed(2)
  } else {
    defaultSlopeValue = props.entry.parameters.slope?.toFixed(1)
  }

  return (
    <>
      <ListItem>
        <FormControl fullWidth error={props.highlightRequiredFields && props.entry.parameters.strengthUnit === ""}>
          <InputLabel sx={{
            transform: "translate(0, 4px) scale(0.75)"
          }} variant="standard" htmlFor="uncontrolled-native-su">
            Strength Unit
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: "strengthUnit",
              id: "uncontrolled-native-su",
            }}
            sx={{
              padding: "6px 0px",
              marginTop: "6px",
              marginBottom: "6px"
            }}
            value={props.entry.parameters.strengthUnit}
            onChange={(e) => {
              props.onEntryChange({
                ...props.entry,
                parameters: {
                  ...props.entry.parameters,
                  strengthUnit: e.target.value as UltimateStrengthUnit
                }
              })
            }}
            fullWidth
            disabled={props.disabled}
          >
            {props.entry.parameters.strengthUnit === "" &&
              <option value="" disabled>Select Unit</option>
            }
            <option value="mpa">MPa</option>
            <option value="psi">psi</option>
          </NativeSelect>
        </FormControl>
      </ListItem>
      <ListItem>
        <TextField
          key={`intercept-${props.maturityMethod.temperatureUnit}-${props.entry.parameters.strengthUnit}`}
          label={`Intercept (${strengthUnitDisplay})`}
          placeholder="Required"
          defaultValue={defaultInterceptValue}
          onBlur={(e) => {
            sanitizeIntercept(e)

            props.onEntryChange({
              ...props.entry,
              parameters: {
                ...props.entry.parameters,
                intercept: floatOrNull(e.target.value)
              }
            })
          }}
          fullWidth
          disabled={props.disabled}
          InputLabelProps={{
            shrink: true
          }}
          error={props.highlightRequiredFields && !props.entry.parameters.intercept}
        />
      </ListItem>
      <ListItem>
        <TextField
          key={`slope-${props.entry.parameters.strengthUnit}`}
          placeholder="Required"
          label={`Slope (${strengthUnitDisplay}/º${props.maturityMethod.temperatureUnit.toUpperCase()}-hr)`}
          defaultValue={defaultSlopeValue}
          onBlur={(e) => {
            sanitizeSlope(e)

            props.onEntryChange({
              ...props.entry,
              parameters: {
                ...props.entry.parameters,
                slope: floatOrNull(e.target.value)
              }
            })
          }}
          fullWidth
          disabled={props.disabled}
          InputLabelProps={{
            shrink: true
          }}
          error={props.highlightRequiredFields && !props.entry.parameters.slope}
        />
      </ListItem>
    </>
  )
}

export default LogarithmicDataView