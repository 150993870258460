import { HyperbolicParameters, LogarithmicParameters } from "../models/curvefit"
import { ArrheniusParameters, MaturityMethodEntry, NurseSaulParameters } from "../models/maturitymethod"
import Placement from "../models/placement"
import Sample from "../models/sample"
import Sensor from "../models/sensor"
import { samplesAfterPlacementDate } from "./samples"
import { hyperbolicStrength, lnStrength, logStrength, maturityFromHyperbolicStrength, maturityFromLnStrength, maturityFromLogStrength } from "./strength"

const gasConstant = 8.31446
const kelvinOffset = 273.15

type MaturityData = {
  sample: Sample
  maturityValue: number
  strengthValue?: number
}

export const maturityForSensor = (sensor: Sensor, placement: Placement, startDate?: Date, endDate?: Date): MaturityData[] => {
  if (sensor.samples === undefined) {
    return []
  }

  let samples = samplesAfterPlacementDate(sensor)
  if (endDate) {
    samples = samples.filter((sample) => {
      return sample.time.valueOf() <= endDate.valueOf()
    })
  }

  let result: MaturityData[] = []
  if (placement.maturityMethod.type === "arrhenius") {
    let runningMaturity = 0
    samples.forEach((sample: Sample, index: number) => {
      let timeDelta = 0
      if (index > 0) {
        timeDelta = (sample.time.valueOf() - samples[index - 1].time.valueOf()) / 3600000
      }
      const parameters = placement.maturityMethod.parameters as ArrheniusParameters
      const maturity = arrheniusMaturity(sample, timeDelta, parameters.activationEnergy, parameters.referenceTemperature)
      runningMaturity += maturity
      const strength = calculateStrength(placement, runningMaturity)
      result.push({
        sample: sample,
        maturityValue: runningMaturity,
        strengthValue: strength
      })
    })
  } else if (placement.maturityMethod.type === "nurse saul") {
    let runningMaturity = 0
    samples.forEach((sample: Sample, index: number) => {
      let timeDelta = 0
      if (index > 0) {
        timeDelta = (sample.time.valueOf() - samples[index - 1].time.valueOf()) / 3600000
      }
      const parameters = placement.maturityMethod.parameters as NurseSaulParameters
      const maturity = nurseSaulMaturity(sample, timeDelta, parameters.datumTemperature)
      runningMaturity += maturity
      const strength = calculateStrength(placement, runningMaturity)
      result.push({
        sample: sample,
        maturityValue: placement.maturityMethod.temperatureUnit === "c" ? runningMaturity : runningMaturity * 9/5,
        strengthValue: strength
      })
    })
  }
  if (startDate) {
    result = result.filter((item) => {
      return item.sample.time.valueOf() >= startDate.valueOf()
    })
  }
  return result
}

const arrheniusMaturity = (sample: Sample, timeDelta: number, activationEnergy: number, referenceTemperature: number): number => {
  const kelvin = sample.temperature + kelvinOffset
  const referenceKelvin = referenceTemperature + kelvinOffset
  return Math.exp(
    (-1.0 * (activationEnergy / gasConstant)) *
        (
          (1.0 / kelvin) -
          (1.0 / referenceKelvin)
        )
  ) * timeDelta
}

const nurseSaulMaturity = (sample: Sample, timeDelta: number, datumTemperature: number): number => {
  return Math.max(0, sample.temperature - datumTemperature) * timeDelta
}

export const calculateStrength = (placement: Placement, maturity: number): number | undefined => {
  if (placement.maturityMethod.curveFit.type === "disabled") {
    return undefined
  } else if (placement.maturityMethod.curveFit.type === "hyperbolic") {
    const parameters = placement.maturityMethod.curveFit.parameters as HyperbolicParameters
    let initialMaturity
    if (placement.maturityMethod.type === "arrhenius") {
      initialMaturity = parameters.initialEquivalentAge
    } else {
      initialMaturity = parameters.initialMaturity
    }
    const strength = hyperbolicStrength(maturity, initialMaturity, parameters.ultimateStrength, parameters.kValue)
    if (parameters.strengthUnit === "psi") {
      return strength * 145.03773773
    } else {
      return strength
    }
  } else if (placement.maturityMethod.curveFit.type === "ln") {
    const parameters = placement.maturityMethod.curveFit.parameters as LogarithmicParameters
    const strength = lnStrength(maturity, parameters.slope, parameters.intercept)
    if (parameters.strengthUnit === "psi") {
      return strength * 145.03773773
    } else {
      return strength
    }
  } else if (placement.maturityMethod.curveFit.type === "log") {
    const parameters = placement.maturityMethod.curveFit.parameters as LogarithmicParameters
    const strength =  logStrength(maturity, parameters.slope, parameters.intercept)
    if (parameters.strengthUnit === "psi") {
      return strength * 145.03773773
    } else {
      return strength
    }
  }
}

export const calculateStrengthFromMaturity = (maturityMethodEntry: MaturityMethodEntry, maturity: number): number | undefined => {
  const parameters = maturityMethodEntry.curveFit.parameters

  if (maturityMethodEntry.curveFit.type === "disabled") {
    return undefined
  } else if (maturityMethodEntry.curveFit.type === "hyperbolic" && parameters.ultimateStrength && parameters.kValue) {
    let initialMaturity
    if (maturityMethodEntry.type === "arrhenius" && parameters.initialEquivalentAge) {
      initialMaturity = parameters.initialEquivalentAge
    } else if (maturityMethodEntry.type === "nurse saul" && parameters.initialMaturity) {
      initialMaturity = parameters.initialMaturity
    } else {
      return undefined
    }
    const strength = hyperbolicStrength(maturity, initialMaturity, parameters.ultimateStrength, parameters.kValue)
    return strength
  } else if (maturityMethodEntry.curveFit.type === "ln" && parameters.slope && parameters.intercept) {
    const strength = lnStrength(maturity, parameters.slope, parameters.intercept)
    return strength
  } else if (maturityMethodEntry.curveFit.type === "log" && parameters.slope && parameters.intercept) {
    const strength = logStrength(maturity, parameters.slope, parameters.intercept)
    return strength
  } else {
    return undefined
  }
}

export const calculateMaturityFromStrength = (maturityMethodEntry: MaturityMethodEntry, strength: number): number | undefined => {
  const parameters = maturityMethodEntry.curveFit.parameters

  if (maturityMethodEntry.curveFit.type === "disabled") {
    return undefined
  } else if (maturityMethodEntry.curveFit.type === "hyperbolic" && parameters.ultimateStrength && parameters.kValue) {
    let initialMaturity
    if (maturityMethodEntry.type === "arrhenius" && parameters.initialEquivalentAge) {
      initialMaturity = parameters.initialEquivalentAge
    } else if (maturityMethodEntry.type === "nurse saul" && parameters.initialMaturity) {
      initialMaturity = parameters.initialMaturity
    } else {
      return undefined
    }
    const maturity = maturityFromHyperbolicStrength(strength, initialMaturity, parameters.ultimateStrength, parameters.kValue)
    return maturity
  } else if (maturityMethodEntry.curveFit.type === "ln" && parameters.slope && parameters.intercept) {
    const maturity = maturityFromLnStrength(strength, parameters.slope, parameters.intercept)
    return maturity
  } else if (maturityMethodEntry.curveFit.type === "log" && parameters.slope && parameters.intercept) {
    const maturity = maturityFromLogStrength(strength, parameters.slope, parameters.intercept)
    return maturity
  } else {
    return undefined
  }
}