import React, { useEffect, useState } from "react"
import { useAppSelector } from "../../../../store/hooks"
import { deleteRecipient, fetchAlertRecipients } from "../../../../network/network"
import { AlertRecipient } from "../../../../models/alertrecipient"
import { Box, CircularProgress, Dialog, Divider, IconButton, List, ListItem, Typography } from "@mui/material"
import { Delete } from "@mui/icons-material"
import AddRecipientView from "./AddRecipientView"
import { LoadingButton } from "@mui/lab"
import EmptyIconView from "../../../reusable/EmptyIconView"

interface Props {
  projectId: number
  placementId: number
}

const AlertRecipientsView = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const accessToken = useAppSelector(state => state.user.token)
  const [recipients, setRecipients] = useState<AlertRecipient[]>([])
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  useEffect(() => {
    const loadAlertsFromNetwork = async () => {
      if (!accessToken) {
        return
      }
      setLoading(true)
      const recipientsResults = await fetchAlertRecipients(accessToken, props.projectId, props.placementId)
      setRecipients(recipientsResults)
      setLoading(false)
    }
    loadAlertsFromNetwork()
  }, [])

  if (loading) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="column"
        sx={{ width: "400px", padding: "12px" }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const handleDeleteRecipient = async (recipient: AlertRecipient) => {
    if (!accessToken) {
      return
    }
    
    const existingRecipients = recipients
    setRecipients(recipients.filter(r => r.id !== recipient.id))
    
    try {
      const deleted = await deleteRecipient(accessToken, props.projectId, props.placementId, recipient.id)
      
      console.log(existingRecipients)
      if (!deleted) {
        setRecipients(existingRecipients)
      }
    } catch (e) {
      console.error(e)
      alert("Failed to delete recipient")
    }
  }

  return (
    <>
      <List sx={{
        width: "100%",
        minHeight: "400px",
      }}>
        {recipients.length === 0 && 
          <Box sx={{
            padding: "36px",
          }}>
            <EmptyIconView backgroundColor="transparent" helperText="No recepients configured to receive alerts. Add a recipient by tapping the button below." />
          </Box>
        }
        {recipients.map(recipient => {
          return (  
            <ListItem key={recipient.id} sx={{
              width: "100%"
            }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{
                width: "100%",
                padding: 0,
                margin: 0,
                alignItems: "center"
              }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{
                  width: "100%"
                }}>
                  <Typography variant="body1" sx={{
                    marginBottom: "6px"
                  }}>
                    {recipient.name}
                  </Typography>
                  <Typography variant="caption" sx={{
                    textTransform: "none"
                  }}>
                    {recipient.contact}
                  </Typography>
                </Box>
                <Typography variant="body1" color="gray" sx={{
                  textTransform: "capitalize"
                }}>
                  {recipient.type}
                </Typography>
                <IconButton sx={{
                  marginLeft: "5px"
                }} onClick={() => handleDeleteRecipient(recipient)}>
                  <Delete />
                </IconButton>
              </Box>
              <Divider />
            </ListItem>
          )
        })}
      </List>
      <LoadingButton variant="contained" sx={{
        margin: "12px",
      }} onClick={() => setAddDialogOpen(true)}>
        + Add a Recipient
      </LoadingButton>
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <AddRecipientView 
          existingRecipients={recipients} 
          projectId={props.projectId} 
          placementId={props.placementId} 
          onClose={() => setAddDialogOpen(false)}
          onRecipientAdded={(newRecipient) => {
            setRecipients([...recipients, newRecipient])
            setAddDialogOpen(false)
          }}
        />
      </Dialog>
    </>
  )
}

export default AlertRecipientsView
