import { Box, Typography } from "@mui/material"
import React from "react"
import { ReactComponent as CompanyIcon } from "./faded-icon.svg"

interface Props {
  backgroundColor?: string
  helperText?: string
}

const EmptyIconView = (props: Props) => {
  return (
    <Box sx={{
      backgroundColor: props.backgroundColor ?? "#F9F6F0",
      width: "100%",
      height: "100%",
      textAlign: "center",
    }} display="flex" alignItems="center" justifyContent="space-around">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CompanyIcon />
        {props.helperText !== undefined &&
          <Typography variant="subtitle1" sx={{
            marginTop: "12px"
          }}>
            {props.helperText}
          </Typography>
        }
      </Box>
    </Box>
  )
}

export default EmptyIconView
