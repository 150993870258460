import { FormControl, InputLabel, ListItem, NativeSelect, TextField } from "@mui/material"
import React from "react"
import { CurveFitEntry, UltimateStrengthUnit } from "../../../../models/curvefit"
import { MaturityMethodEntry } from "../../../../models/maturitymethod"
import { floatOrNull } from "../TemperatureLimitsView"

interface Props {
  maturityMethod: MaturityMethodEntry
  entry: CurveFitEntry
  onEntryChange: (entry: CurveFitEntry) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const HyperbolicDataView = (props: Props) => {
  const maximumStrengthFractionDigits = props.entry.parameters.strengthUnit === "mpa" ? 2 : 0

  const sanitizeInitialMaturity = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseInt(e.target.value).toString()

    if (isNaN(parseInt(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeKValue = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseFloat(e.target.value).toFixed(6)

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeUltimateStrength = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseFloat(e.target.value).toFixed(maximumStrengthFractionDigits) //.toLocaleString(undefined, {maximumFractionDigits: maximumStrengthFractionDigits})

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  const sanitizeInitialEquivalentAge = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    e.target.value = parseFloat(e.target.value).toFixed(1) //.toLocaleString(undefined, {maximumFractionDigits: 1})

    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = ""
    }
  }

  return (
    <>
      <ListItem sx={{
        paddingLeft: "0px",
        paddingRight: "0px"
      }}>
        <FormControl fullWidth error={props.highlightRequiredFields && props.entry.parameters.strengthUnit === ""}>
          <InputLabel sx={{
            transform: "translate(0, 4px) scale(0.75)"
          }} variant="standard" htmlFor="uncontrolled-native-su">
            Strength Unit
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: "strengthUnit",
              id: "uncontrolled-native-su",
            }}
            sx={{
              padding: "6px 0px",
              marginTop: "6px",
              marginBottom: "6px"
            }}
            value={props.entry.parameters.strengthUnit}
            onChange={(e) => {
              console.log("Strength Unit: ", e.target.value)

              props.onEntryChange({
                ...props.entry,
                parameters: {
                  ...props.entry.parameters,
                  strengthUnit: e.target.value as UltimateStrengthUnit
                }
              })
            }}
            fullWidth
            disabled={props.disabled}
          >
            {props.entry.parameters.strengthUnit === "" &&
              <option value="" disabled>Select Unit</option>
            }
            <option value="mpa">MPa</option>
            <option value="psi">psi</option>
          </NativeSelect>
        </FormControl>
      </ListItem>

      {props.maturityMethod.temperatureUnit &&
        <>
          <ListItem sx={{
            paddingLeft: "0px",
            paddingRight: "0px"
          }}>
            { props.maturityMethod.type === "nurse saul" &&
              <TextField
                key={`initialMaturity-${props.maturityMethod.temperatureUnit}`}
                label={`Initial Maturity (º${props.maturityMethod.temperatureUnit.toUpperCase()}-hr)`}
                defaultValue={props.entry.parameters.initialMaturity && props.entry.parameters.initialMaturity.toFixed(0)}
                placeholder="Required"
                onBlur={(e) => {
                  sanitizeInitialMaturity(e)

                  props.onEntryChange({
                    ...props.entry,
                    parameters: {
                      ...props.entry.parameters,
                      initialMaturity: floatOrNull(e.target.value)
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
                error={props.highlightRequiredFields && !props.entry.parameters.initialMaturity}
              />
            }
            { props.maturityMethod.type === "arrhenius" &&
              <TextField
                key="initialEquivalentAge"
                label="Initial Eq. Age (hr)"
                defaultValue={props.entry.parameters.initialEquivalentAge && props.entry.parameters.initialEquivalentAge.toFixed(1)}
                placeholder="Required"
                onBlur={(e) => {
                  sanitizeInitialEquivalentAge(e)

                  props.onEntryChange({
                    ...props.entry,
                    parameters: {
                      ...props.entry.parameters,
                      initialEquivalentAge: floatOrNull(e.target.value)
                    }
                  })
                }}
                fullWidth
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true
                }}
                error={props.highlightRequiredFields && !props.entry.parameters.initialEquivalentAge}
              />
            }
          </ListItem>
          <ListItem sx={{
            paddingLeft: "0px",
            paddingRight: "0px"
          }}>
            <TextField
              key={`kValue-${props.maturityMethod.temperatureUnit}`}
              label="k-value"
              defaultValue={props.entry.parameters.kValue && props.entry.parameters.kValue.toFixed(6)}
              placeholder="Required"
              onBlur={(e) => {
                sanitizeKValue(e)

                props.onEntryChange({
                  ...props.entry,
                  parameters: {
                    ...props.entry.parameters,
                    kValue: floatOrNull(e.target.value)
                  }
                })
              }}
              fullWidth
              disabled={props.disabled}
              InputLabelProps={{
                shrink: true
              }}
              error={props.highlightRequiredFields && !props.entry.parameters.kValue}
            />
          </ListItem>
          {props.entry.parameters.strengthUnit !== "" &&
          <ListItem sx={{
            paddingLeft: "0px",
            paddingRight: "0px"
          }}>
            <TextField
              key={`ultimateStrength-${props.entry.parameters.strengthUnit}`}
              label={`Ultimate Strength (${props.entry.parameters.strengthUnit === "mpa" ? "MPa" : "psi"})`}
              defaultValue={props.entry.parameters.ultimateStrength && props.entry.parameters.ultimateStrength.toFixed(maximumStrengthFractionDigits)}
              placeholder="Required"
              onBlur={(e) => {
                sanitizeUltimateStrength(e)

                props.onEntryChange({
                  ...props.entry,
                  parameters: {
                    ...props.entry.parameters,
                    ultimateStrength: floatOrNull(e.target.value)
                  }
                })
              }}
              fullWidth
              disabled={props.disabled}
              InputLabelProps={{
                shrink: true
              }}
              error={props.highlightRequiredFields && !props.entry.parameters.ultimateStrength}
            />
          </ListItem>
          }
        </>
      }
    </>
  )
}

export default HyperbolicDataView
