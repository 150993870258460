import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import React, { useState } from "react"
import Placement, { convertToPlacementEntry, isPlacementEntryEqual, PlacementEntry } from "../../models/placement"
import SettingsIcon from "@mui/icons-material/Settings"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import PlacementSpecificationsView from "./specifications/PlacementSpecificationsView"
import AlertContainerView from "./specifications/alerts/AlertContainerView"
import Project from "../../models/project"

interface Props {
  placement: Placement
  selected: boolean
  showSettings: boolean
  project: Project
  onPlacementUpdate: (placement: Placement) => void
}

const PlacementView = (props: Props) => {
  const [placementSpecsAnchorElement, setPlacementSpecAnchorElement] = useState<HTMLElement | null>(null)
  const [specsDialogOpen, setSpecsDialogOpen] = useState(false)
  const [alertsDialogOpen, setAlertsDialogOpen] = useState(false)
  const [specsChanged, setSpecsChanged] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setPlacementSpecAnchorElement(event.currentTarget)
  }

  const openSpecsDialog = () => {
    setSpecsChanged(false)
    setPlacementSpecAnchorElement(null)
    setSpecsDialogOpen(true)
  }

  const openAlertsDialog = () => {
    setPlacementSpecAnchorElement(null)
    setAlertsDialogOpen(true)
  }

  const handleSpecsChange = (entry: PlacementEntry) => {
    setSpecsChanged(!isPlacementEntryEqual(entry, convertToPlacementEntry(props.placement)))
  }

  const closeSpecsDialog = () => {
    if (!specsChanged) {
      setSpecsDialogOpen(false)
    } else {
      setWarningDialogOpen(true)
    }
  }

  const settingsButton = () => {
    return (
      <>
        <IconButton 
          sx={{
            color: "white",
            margin: 0,
            padding: 0,
            width: "32px",
            height: "32px",
            "&.Mui-disabled": {
              color: "#ffffff44"
            }
          }}
          onClick={togglePopover}
          disabled={!props.showSettings}
        >
          <SettingsIcon />
        </IconButton>
        <Menu
          anchorEl={placementSpecsAnchorElement}
          open={popoverIsOpen}
          onClose={() => { setPlacementSpecAnchorElement(null) }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={ openSpecsDialog }>Specifications</MenuItem>
          <Divider variant="middle" component="li" />
          <MenuItem onClick={ openAlertsDialog }>Alerts</MenuItem>
        </Menu>
        <Dialog
          open={specsDialogOpen}
          onClose={closeSpecsDialog}
          maxWidth="xs"
          fullWidth={true}
        >
          <PlacementSpecificationsView 
            placement={props.placement} 
            project={props.project}
            onPlacementUpdate={ (p) => {
              props.onPlacementUpdate(p)
              setSpecsDialogOpen(false)
            }}
            onClose={closeSpecsDialog}
            onEntryChange={handleSpecsChange}
          />
        </Dialog>
        <Dialog
          open={warningDialogOpen}
          onClose={() => setWarningDialogOpen(false)}
        >
          <DialogContent>
            <DialogContentText>
              You have unsaved changes that will be lost. Are you sure you want to cancel?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => {
              setSpecsDialogOpen(false)
              setWarningDialogOpen(false)
            }}>Yes</Button>
            <Button variant="contained" onClick={() => {
              setWarningDialogOpen(false)
            }} autoFocus>
              Go back
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog 
          sx={{    
            "& .MuiDialog-container": {
              alignItems: "flex-start"
            },
            "& .MuiDialog-paper": {
              maxWidth: "400px"
            }
          }} 
          maxWidth="sm" 
          fullWidth={false} 
          scroll="paper" 
          open={alertsDialogOpen} 
          onClose={() => { setAlertsDialogOpen(false) }}
        >
          <AlertContainerView
            projectId={props.project.id}
            placementId={props.placement.id}
            onBack={() => {
              setAlertsDialogOpen(false)
            }}
          />
        </Dialog>
      </>
    )
  }

  const popoverIsOpen = Boolean(placementSpecsAnchorElement)

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{
      color: props.selected ? "white" : "black",
      width: "100%"
    }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="flex-start"
        sx={{
          padding: "0px 24px"
        }}
      >
        <Typography variant="h5" sx={{
          wordBreak: "break-word"
        }}>{props.placement.name}</Typography>
        <Typography variant="caption" sx={{
          marginTop: "6px",
          color: props.selected ? "white" : ""
        }}>{props.placement.sensors.length} sensors</Typography>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        {props.selected && props.showSettings == false &&
          <Tooltip title="You have limited access to this placement">
            <span>
              {settingsButton()}
            </span>
          </Tooltip>
        }
        {props.selected && props.showSettings &&
          settingsButton()
        }
        {!props.selected &&
          <Box sx={{
            width: "32px",
            heitht: "32px"
          }} />
        }
        <ArrowForwardIosIcon />
      </Box>
    </Box>
  )
}

export default PlacementView
